<template>
  <base-layout>



    <ion-content id="wcpt-presentation-detail" class="has-header has-subheader has-bottom-banner">
      <div class="presentation-head mb-3">
        <div class="card border-0  h-100">
          <div class="card-body pt-2 pb-0 px-4 mx-2">
            <strong class="title  d-block "><span
                v-if="presentation.code"> {{ presentation.code }}  - </span><span
                v-html="presentation.title"></span></strong>
            <div class="time" v-if="presentation.start_time">
              <template v-if="presentation.start_time == presentation.end_time">
                {{ moment(session.start_time).format('dddd HH:mm') }} -
                {{ moment(session.end_time).format('HH:mm') }}
              </template>
              <template v-if="presentation.start_time != presentation.end_time">
                {{ moment(session.start_time).format('dddd') }} {{ moment(presentation.start_time).format('HH:mm') }} -
                {{ moment(presentation.end_time).format('HH:mm') }}
              </template>
            </div>
          </div>
          <div class="code-wrapper mx-3 mt-2 py-4"
               :style="{'color': session.font_color, 'background-color':session.color}">
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row row-cols-1 justify-content-start">
          <div class="col-12 px-0 content-left border-right">
            <div class="card border-0">
              <div class="container-fluid px-3 mt-3" style="line-height: 40px;">
                <div class="row  justify-content-center justify-content-md-start mb-1 px-1"
                     v-if="presentation.id && presentation.session && presentation.session.id">
                  <div class="col col-xs-6 px-1 pb-2" style="max-width: 200px;">
                    <FavoriteButton :fav-id="favId" :parent-id="'sessions/' + presentation.session.id"
                                    v-if="presentation.id && presentation.session && presentation.session.id"></FavoriteButton>
                  </div>
                  <div class="col col-xs-6 px-1 pb-2" style="max-width: 200px;">
                    <NoteButton :fav-id="favId" v-if="presentation.id" :key="presentation.id"></NoteButton>
                  </div>
                  <div v-if="abstract && abstract.id" class="col col-xs-6 px-1 pb-2" style="max-width: 200px;">
                    <router-link v-if="abstract && abstract.id" class="btn btn-secondary opacity-75 d-block"
                                 style="text-decoration: none; !important; font-size: 0.9rem !important;"
                                 :to="abstract ?  '/app/abstracts/'+ abstract.id: ''">
                      <font-awesome-icon :icon="['fal', 'file-alt']"/>
                      {{
                        wordings[currentLanguage].PRESENTATION_ABSTRACT
                      }}
                    </router-link>
                  </div>
                  <div class="col col-xs-6 px-1 pb-2 d-none d-md-block" style="max-width: 200px;">
                    <div class="btn btn-secondary opacity-75 d-block px-2 px-md-1 py-1" style="text-decoration:none; max-width: 200px;" @click="handleFile(presentation.file_id,presentation.file_name,true)"
                         v-if="(presentation.file_id)"><i class="icon ion-navicon-round"></i>&nbsp;Vortrags-PDF</div>
                  </div>
                  <div class="col col-xs-6 px-1 pb-2 d-none d-md-block" style="max-width: 200px;">
                    <div class="btn btn-secondary opacity-75 d-block px-2 px-md-1 py-1" style="text-decoration:none; max-width: 200px;" @click="handleFile(presentation.poster_file_id,presentation.poster_file_name,true)"
                         v-if="(presentation.poster_file_id)"><i class="icon ion-navicon-round"></i>&nbsp;Poster-PDF</div>
                  </div>


                  <div class="col px-1 d-none d-md-block" v-if="polls && polls.length > 0">
                    <a
                        class="btn btn-secondary opacity-75 d-block px-2 px-md-1"
                        style="text-decoration: none;font-size: 0.9rem"
                        v-for="poll in polls" :key="poll.id"
                        :href="'#/app/polls/' + poll.id + '/presentations/' + presentation.id"
                    >
                      <font-awesome-icon :icon="['fas', 'poll']"/>
                      {{ wordings[currentLanguage].EVALUATE }}
                    </a>
                  </div>

                </div>
                <div class="row my-2 d-md-none">
                  <div class="col  px-2 pb-2 " >
                    <div class="btn btn-secondary opacity-75 d-block px-2 px-md-1 py-1" style="text-decoration:none; max-width: 200px;" @click="handleFile(presentation.file_id,presentation.file_name,true)"
                         v-if="(presentation.file_id)"><i class="icon ion-navicon-round"></i>&nbsp;Vortrags-PDF</div>
                  </div>

                  <div class="col px-2">
                    <a
                        class="btn btn-secondary opacity-75 d-block px-2 px-md-1"
                        style="text-decoration: none;font-size: 0.9rem"
                        v-for="poll in polls" :key="poll.id"
                        :href="'#/app/polls/' + poll.id + '/presentations/' + presentation.id"
                    >
                      <font-awesome-icon :icon="['fas', 'poll']"/>
                      {{ wordings[currentLanguage].EVALUATE }}
                    </a>
                  </div>
                  <div class="col px-1 d-none d-md-block" v-if="polls && polls.length > 0">
                    <a
                        class="btn btn-secondary opacity-75 d-block px-2 px-md-1"
                        style="text-decoration: none;font-size: 0.9rem"
                        v-for="poll in polls" :key="poll.id"
                        :href="'#/app/polls/' + poll.id + '/presentations/' + presentation.id"
                    >
                      <font-awesome-icon :icon="['fas', 'poll']"/>
                      {{ wordings[currentLanguage].EVALUATE }}
                    </a>
                  </div>
                </div>

              </div>
              <div class="card-body py-0">
                <div class="stream-container"
                     v-if="presentation && (presentation.video_link || presentation.posterlink) && (!isLiveSession || (liveStreamHasEnded))">
                  <div class="stream" :style="{'padding-top':isEposter && presentation.posterlink ? '60%' : '56,25%'}">
                    <div>
                      <iframe v-if="presentation.video_link" :src="presentation.video_link"
                              style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0"
                              allow="autoplay; fullscreen" allowfullscreen></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-0">
              <div class="pt-3 px-3 " v-html="presentation.outline" v-if="presentation.outline "></div>

              <div class="container-fluid p-0 px-1 mt-md-2">
                <div class="row ps-1  mt-2">
                  <h6 class="col ms-2" v-if="persons && persons.length > 0">
                    {{ wordings[currentLanguage].SPEAKER_HEADER }}<span v-if="persons.length > 1">s</span>
                  </h6>
                </div>
                <div class="ps-1">
                  <div
                      class="row row-cols-1  row-cols-md-5 g-2  ps-md-2 g-md-3 row-eq-height justify-content-start">
                    <template v-for="person in persons" :key="person.id">
                      <person-item :person="person"></person-item>
                    </template>
                  </div>
                </div>

                <div class="row ps-1 mt-3">
                  <h6 class="col ms-2" v-if="mainSession">
                    {{ wordings[currentLanguage].SESSION_HEADER }}</h6>
                </div>
                <div
                    class="row row-cols-1 row-cols-md-3 row-cols-lg-4  row-cols-xl-5 g-2 g-md-4 row-eq-height justify-content-start ps-md-2">
                  <session-item :session="mainSession" v-if="mainSession"></session-item>
                </div>
                <div class="row ps-1 mt-3">
                  <h6 class="col ms-2" v-if="alsoSessions && alsoSessions.length > 0">
                    {{ wordings[currentLanguage].SESSION_HEADER_ALSO }}</h6>
                </div>
                <div
                    class="row row-cols-1 row-cols-md-3 row-cols-lg-4  row-cols-xl-5 g-2 g-md-4 row-eq-height justify-content-start ps-md-2">
                  <template v-for="session in alsoSessions" :key="session.id">
                    <session-item :session="session"></session-item>
                  </template>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </base-layout>

</template>

<script>
import {IonContent,modalController} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapState} from "vuex";
import moment from "moment";
import FavoriteButton from "@/components/favorite/FavoriteButton";
import {documentTextOutline, downloadOutline} from "ionicons/icons";
import SessionItem from "@/components/SessionItem";

addIcons({"document-text-outline": documentTextOutline});
addIcons({"download-outline": downloadOutline});
import NoteButton from "@/components/favorite/NoteButton";
import PersonItem from "@/components/PersonItem";
import PdfModal from "@/views/Industry/PdfModal";


//moment.locale("de");

import {addIcons} from "ionicons";

export default defineComponent({
  name: "PresentationDetail",
  data() {
    return {
      presentation: {},
      persons: [],
      sessions: [],
      absAvailable: false,
      session: {},
      favId: null,
      abstract: [],
      isLiveSession: false,
      sessionHasStarted: false,
      sessionHasEnded: false,
      liveStreamHasStarted: false,
      liveStreamHasEnded: false,
      liveSessionActive: false,
      zoomSessionActive: false,
      actualTime: "",
      utcOffset: '+00:00',
      polls: []

    };
  },
  components: {
    IonContent,
    FavoriteButton,
    NoteButton,
    PersonItem,
    SessionItem
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('wordings', ['wordings', 'currentLanguage']),
  },
  methods: {
    ...mapActions('googleanalytics', ['trackWithLabel']),
    ...mapActions("presentations", [
      "getPresentationById",
      "getPresentationByLinkId",
      "getPersons",
      "getSessions",
      "getAbstractForPresentation",
      "getSessionIncludeLinks"
    ]),
    ...mapActions("abstracts", [
      "getAbstract",
    ]),
    ...mapActions("sessions", [
      "getSingleSession",
    ]),
    ...mapActions('googleanalytics', ['trackWithLabel']),
    ...mapActions("cmetracking", [
      "uploadTrackingEvents",
      "trackEvent",
    ]),
    ...mapActions("settings", [
      "getSetting",
    ]),
    ...mapActions('polls', ['getPollForState']),

    async getServerTime() {
      try {
        let time_url =
            "https://mantel-api.mobile.documedias.systems/v2/timestamp";
        return (await this.axios.get(time_url)).data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    checkTime(session) {
      let dateNow;
      let sessionStart;
      let sessionEnd;
      let liveSessionStart;
      let liveSessionEnd;
      let zoomSessionEnd;
      dateNow = new Date(moment.utc());//new Date(this.actualTime);
      sessionStart = new Date(session.start_time + this.utcOffset);
      sessionEnd = new Date(session.end_time + this.utcOffset);

      this.sessionHasStarted = dateNow > sessionStart;
      this.sessionHasEnded = dateNow > sessionEnd;
      liveSessionStart = moment(sessionStart).subtract(5, "minutes");
      liveSessionEnd = moment(sessionEnd).add(10, "minutes");
      zoomSessionEnd = moment(sessionEnd).add(10, "minutes");
      this.liveStreamHasStarted = dateNow >= liveSessionStart;
      this.liveStreamHasEnded = dateNow >= liveSessionEnd;
      this.liveSessionActive =
          dateNow >= liveSessionStart && dateNow <= liveSessionEnd;
      this.zoomSessionActive =
          dateNow >= liveSessionStart && dateNow <= zoomSessionEnd;
      this.interval = setInterval(() => {
        this.actualTime = moment(this.actualTime).add(5, "seconds");
        dateNow = moment(this.actualTime);
        sessionStart = new Date(session.start_time + this.utcOffset);
        sessionEnd = new Date(session.end_time + this.utcOffset);
        this.sessionHasStarted = dateNow > sessionStart;
        this.sessionHasEnded = dateNow > sessionEnd;

        liveSessionStart = moment(sessionStart).subtract(5, "minutes");
        liveSessionEnd = moment(sessionEnd).add(10, "minutes");
        zoomSessionEnd = moment(sessionEnd).add(10, "minutes");
        this.liveStreamHasStarted = dateNow >= liveSessionStart;
        this.liveStreamHasEnded = dateNow >= liveSessionEnd;
        this.liveSessionActive =
            dateNow >= liveSessionStart && dateNow <= liveSessionEnd;
        this.zoomSessionActive =
            dateNow >= liveSessionStart && dateNow <= zoomSessionEnd;
      }, 5000);
    },
    getPDFLink(link, rewrite) {
      let reslink = link;
      if (rewrite) {
        reslink = 'https://fileserver.s3-de-central.profitbricks.com/' + link;//TEST
      }
      return reslink;
    },

    async handleFile(file,name) {
      let link = this.getPDFLink(file, true);
      let viewerLink = 'https://pdf.abstractserver.com/viewer/#/' + link;
      console.log(viewerLink)

      this.modal = await modalController
          .create({
            component: PdfModal,
            cssClass: 'pdf-modal',
            componentProps: {
              title: name,
              link: viewerLink
            },
          })


      let trackData = {
        user: this.user,
        item : {name: name, id:file},
        event: 'viewed',
        type:'document',
        itemId: file,
      }
      this.trackEvent(trackData);
      return this.modal.present();
    },

  },

  async created() {
  },
  watch: {
    '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(route) {
        if (route.name == 'Presentation Detail') {
          this.moment = moment;
          this.presentation = await this.getPresentationByLinkId(route.params.id);
          this.favId = 'presentations/' + this.presentation.id;
          this.session = this.presentation.session
          this.utcOffset = await this.getSetting('timezone');
          this.isLiveSession = this.session.location && this.session.location.id != 'da7ad0c0-3ed1-4500-1302-080000000018' ? true : false;
          this.actualTime = await this.getServerTime();
          this.checkTime(this.session);
          this.abstract = await this.getAbstractForPresentation(this.presentation.pres_id);
          let abs = await this.getAbstract(this.presentation.abstract_id);
          this.absAvailable = !abs ? false : true


          this.persons = await this.getPersons(this.presentation.pres_id);
          let includedSessions = await this.getSessionIncludeLinks(this.presentation);
          this.mainSession = includedSessions.mainSession;
          this.alsoSessions = includedSessions.alsoSessions;
          this.trackWithLabel(this.presentation.title);
          this.polls = await this.getPollForState(window.location)

          let trackData = {
            user: this.user,
            item: this.presentation,
            event: 'enter',
            type: 'presentation',
            itemId: this.presentation.id,
          }
          this.trackEvent(trackData)
        } else {
          let trackData = {
            user: this.user,
            item: this.presentation,
            event: 'leave',
            type: 'presentation',
            itemId: this.presentation.id,
          }
          this.trackEvent(trackData)
        }
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.presentation-head {
  display: inline-block;
  border-bottom: none;

  .code-wrapper {
    position: absolute;
    left: 0px;
    height: 100%;
    width: 8px;
    border-radius: 5px;
  }

  .item-header-gradient {
    position: absolute;
    //z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url('../../assets/shadow.png') no-repeat bottom center;
    background-size: cover;
  }

  .title {
    font-size: 1.1rem;
  }

  .type {
    font-size: 0.8rem;
    display: inline-block;
    line-height: 0.8rem;

    strong {
      display: inline-block;
      font-size: 0.7rem;
    }
  }

  .time {
    font-size: 0.8rem;
  }
}

.action-buttons {
  .actn-icon {
    font-size: 1.3rem !important;
  }

  .actn-text {
    font-size: 0.7rem !important;
    line-height: 0.8rem !important;
  }
}


.stream-container {
  width: 70%;
  //max-width: 1100px;

  .stream {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;

    .info-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px dashed grey;

      .info {
        flex: 0 0 400px;
        font-size: 2rem;
        line-height: 2.2rem;
        color: grey;
        font-weight: bold;
      }
    }
  }
}
@media(max-width: 767px) {
  .stream-container{
    width:100%
  }
}

</style>
